import React from "react";
import Sbanner from "../../asset/Local/Banner.png";

export default function Banner() {
    return (
        <>
            <div>
                <div className="card text-bg-dark">
                    <img src={Sbanner} className="card-img" alt=""></img>
                    <div className="card-img-overlay d-flex justify-content-center align-items-center ">
                        {/* <h1 className="card-title text-black">THANAPAT GOLD SMITH LIMITED</h1> */}
                    </div>
                </div>
            </div>
        </>
    );
}