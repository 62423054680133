import React from "react";

export default function Aboutus() {
  return (
    <>
      <h4>ห้างทองกมลพรรณ : Kamonphan Gold Shop</h4>
      <p>- จำหน่าย ทองรูปพรรณ ทองแท่ง จากเยาวราช น้ำหนักเต็ม 96.5% ด้วยเงินสด บัตรเครดิต บัตรอิออน บัตรเฟิร์สช้อย</p>
      <p>- รับฝากทองรูปพรรณ และ ทองแท่ง ราคาสูง</p>
      <p>- รับซื้อและแลกเปลี่ยนทองเก่าด้วยราคาสูง</p>
      <p>- รับ เลี่ยมพระ แหวน และ งานสั่งทุกประเภท ด้วยช่างฝีมือประณีต</p>
    </>
  );
}
