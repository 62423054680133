import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import Home from './components/pages/Home';
import Aboutus from './components/pages/Aboutus';
import Contactus from './components/pages/Contactus';
import Policypage from './components/pages/Policypage';
import AppNavbar from './components/AppNavbar';
import Footer from './components/Footer';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import './App.css';
import 'react-toastify/ReactToastify.min.css';

function App() {
  window.document.title = process.env.REACT_APP_APP_NAME;

  return (
    <div className='App'>
      <Router>

        <AppNavbar />

        <Container>
          <Routes>
            <Route path='/home' element={<Home />} />
            <Route path='about' element={<Aboutus />} />
            <Route path='contact' element={<Contactus />} />
            <Route path='privacy-policy' element={<Policypage />} />
            <Route path='/' element={<Navigate to='/home' />} />
          </Routes>
        </Container>

        <Footer />

      </Router>
      <ToastContainer hideProgressBar={true} position='top-right' newestOnTop />
    </div>
  );
}

export default App;
