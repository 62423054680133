import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
/* import iconphone from "../asset/iceon_phone.png";
import iconline from "../asset/iceon_line.png"; */
import "./AppNavbar.css";
import ALogo from "../asset/Local/logo.png";

function AppNavbar() {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-end">
        <br />
        {/*         <a href="#">
          <img alt="" src={iconphone} style={{ width: "40px", height: "40px" }}></img>
        </a>{" "}
        <a href="#">
          <img alt="" src={iconline} style={{ width: "40px", height: "40px" }}></img>
        </a> */}
      </div>

      <div className="d-flex justify-content-center">
        <img alt="" src={ALogo} className="d-inline-block align-top ImgLogoNavbar" />
      </div>

      <Navbar expanded={expanded} expand="sm" /* variant="dark" */>
        <Container>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : true)}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >

            <Nav>
              <Link
                to="/"
                className="nav-link TextNavbar"
                onClick={() => setExpanded(false)}
              >
                ดูสินค้า
              </Link>
              <Link
                to="/about"
                className="nav-link TextNavbar"
                onClick={() => setExpanded(false)}
              >
                เกี่ยวกับเรา
              </Link>
              <Link
                to="/contact"
                className="nav-link TextNavbar"
                onClick={() => setExpanded(false)}
              >
                ติดต่อเรา
              </Link>
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default AppNavbar;
