import React from "react";

export default function Contactus() {
    return (
        <>
            <h4>ติดต่อเราได้ที่: </h4>
            <p><span>🏠 </span>78 ถนนหนองจิก ตำบลสะบารัง อำเภอเมืองปัตตานี จ.ปัตตานี 94000</p>
            <p><span>📍 </span>ปัตตานี ประเทศไทย</p>
            <p><span>📞 </span>064 965 8783</p>
        </>
    );
}