import axios from 'axios';
const goldK = process.env.REACT_APP_GOLD_KEY;
const goldUrl = process.env.REACT_APP_GOLD_URL;

export async function getTodayRate() {
    let config = {
      url: `${goldUrl}goldprice/full`,
      headers: {
        'api-key-std': goldK
      }
    };
    try {
      const res = await axios.request(config)
      return res.data;
    } catch (error) {
      throw error;
    }
  }
  