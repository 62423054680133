import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './Footer.css';

export default function Footer() {
  return (
    <Container className='Footer' fluid>
      <div id='Footer-bar' className='Container-fluid'>
        <div>
          <Link
            to="/privacy-policy">
            privacy policy
          </Link>
        </div>
        <span id='txtCopyright'> Copyright 2024 All Rights Reserved </span>
      </div>
    </Container>
  );
}
