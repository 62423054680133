import { React, useState, useEffect } from "react";
import { getTodayRate } from "../../services/https";
import ThDate from "../forms/thDate";

export default function Goldprice() {
    const [exData, setExData] = useState('');

    useEffect(() => {
        getTodayRate()
            .then((data) => {
                setExData(data);
            })
    }, []);

    return (
        <>
            <div>
                <div className="goldp-container">
                    <div className="row">
                        <div className="col-md-3 text-white text-center">
                            <p>ราคาทองวันนี้</p>
                            <ThDate />
                        </div>
                        <div className="col-md-3 goldp-table">
                            <table className="table">
                                <tbody>
                                    <tr className="goldp-header">
                                        <td>ขายทองเเท่ง</td>
                                    </tr>
                                    {exData.full && exData.full.goldbar && exData.full.goldbar.sale ?
                                        <tr className="goldp-content">
                                            <td>{exData.full.goldbar.sale}</td>
                                        </tr> : <tr><td></td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="col-md-3 goldp-table">
                            <table className="table">
                                <tbody>
                                    <tr className="goldp-header">
                                        <td>ซื้อทองเเท่ง</td>
                                    </tr>
                                    {exData.full && exData.full.goldbar && exData.full.goldbar.buy ?
                                        <tr className="goldp-content">
                                            <td>{exData.full.goldbar.buy}</td>
                                        </tr> : <tr><td></td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-3 goldp-table">
                            <table className="table">
                                <tbody>
                                    <tr className="goldp-header">
                                        <td>ซื้อทองรูปพรรณ</td>
                                    </tr>
                                    {exData.full && exData.full.gold && exData.full.gold.buy ?
                                        <tr className="goldp-content">
                                            <td>{exData.full.gold.buy}</td>
                                        </tr> : <tr><td></td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
