import React from "react";
import { Row } from "react-bootstrap";
import Goldprice from "../shared/Goldprice";
import Banner from "../shared/Banner";
import Catalog from "../shared/Catalog";

export default function Home() {
  return (
    <>
      <Row className="justify-content-center">

        <Banner />

        <Goldprice />

        <Catalog />

      </Row>
    </>
  );
}
